import React, {useEffect, useRef, useState} from 'react';
import axios from 'axios';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';

const reorderOutline = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);

    result.splice(endIndex, 0, removed);

    return result;
};

const SlideOutlineWizard = ({learningGoalId}) => {
    const [outline, setOutline] = useState( [""])
    const [isLoadingOutline, setIsLoadingOutline] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [completedIds, setCompletedIds] = useState([])

    const outlineRef = useRef()

    useEffect(() => {
        if(!outlineRef.current) { return }

        const formControls = outlineRef.current.querySelectorAll('.form-control')

        formControls[formControls.length - 1].focus()
    }, [outlineRef])

    useEffect(() => {
        (async () => {
            await fetchOutline()
        })()
    }, [])

    const fetchOutline = async () => {

        setIsLoadingOutline(true)

        try {
            const response = await axios.get(location.href, {
                headers: { 'Accept': 'application/json' }
            })

            if(response.status === 200) {
                setOutline([...response.data, ""])
            }
        } finally {
            setIsLoadingOutline(false)
        }

    }

    const onItemChange = (idx, value) => {
        const updatedOutline = [...outline]
        updatedOutline[idx] = value

        if (idx === outline.length - 1 && value !== "") {
            updatedOutline.push("")
        }

        setOutline(updatedOutline.filter((v, idx) => v !== "" || idx === updatedOutline.length - 1))
    }

    const onCreateSlides = async () => {
        const slides = outline.slice(0, -1)

        setIsLoading(true)

        let request = await axios.post(`/administration/learning_goals/${learningGoalId}/presentation_slides/batches`, {slides}, {
            headers: {
                'Accept': 'application/javascript',
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
            }
        })

        if(request.status !== 201) {
            setIsLoading(false)
            return
        }

        const eventSource = new EventSource(`/administration/learning_goals/${learningGoalId}/presentation_slides/batches/${request.data.job}/progress`)

        eventSource.onmessage = (event) => {
            const data = JSON.parse(event.data)

            if(data.status === 'timeout') {
                eventSource.close()
                setCompletedIds([])
                setIsLoading(false)
            } else {
                const index = parseInt(data.index, 10)
                if(index === slides.length - 1) {
                    eventSource.close()
                    location.href = `/administration/learning_goals/${learningGoalId}`
                } else {
                    setCompletedIds((ids) => [...ids, index])
                }
            }
        }

        eventSource.onerror = () => {
            eventSource.close()
            setCompletedIds([])
            setIsLoading(false)
        };
    }

    const onRemoveItem = (idx) => {
        if(isLoading) { return }

        setOutline(outline.filter((_, i) => i !== idx))
    }

    const onDragEnd = ({destination, source}) => {
        if(!destination || destination.index === source.index) {
            return
        }

        setOutline(reorderOutline(outline, source.index, destination.index));
    }

    if(isLoading) {
        return <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', minHeight: '200px', minWidth: '100%'}}>
            <ul className='list-group'>
                {
                    outline.slice(0, -1).map((value, idx) => {
                        const isComplete = completedIds.includes(idx)

                        return <li key={idx} className='list-group-item'>
                            {!isComplete && <i className='fas fa-sync fa-spin mr-2'/>}
                            {isComplete && <i className='fas fa-check mr-2 text-success'/>}
                            {value}
                        </li>
                    })
                }
            </ul>
        </div>
    }

    if(isLoadingOutline) {
        return <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', minHeight: '200px', minWidth: '100%'}}>
            <i className='fas fa-sync fa-spin fa-2x' />
            <span className='mt-3'>Generiere Gliederung...</span>
        </div>
    }

    return <>
        <div ref={outlineRef}>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {provided => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            {outline.map((topic, idx) =>
                                <Draggable key={idx} draggableId={idx.toString()} index={idx} isDragDisabled={topic === "" || isLoading}>
                                    {provided => (
                                        <>
                                            <div
                                                ref={provided.innerRef} {...provided.draggableProps}
                                                className="input-group mb-2" key={idx}>
                                                {topic !== "" &&
                                                    <div className="input-group-prepend" {...provided.dragHandleProps}>
                                                        <span className="input-group-text">
                                                             <i className="fal fa-arrows-up-down"/>
                                                        </span>
                                                    </div>
                                                }
                                                <input className="form-control" type="text" value={topic}
                                                       onChange={({target: {value}}) => onItemChange(idx, value)}
                                                />
                                                {topic !== "" &&
                                                    <div className="input-group-append input-group-append-button">
                                                        <button type="button" className="btn btn-danger" onClick={() => onRemoveItem(idx)}>
                                                            <i className="fa-light fa-trash"/>
                                                        </button>
                                                    </div>
                                                }
                                            </div>
                                        </>
                                    )}
                                </Draggable>
                            )}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
        <div className="row">
            <div className="col">
                <button type="button" className="btn btn-primary mt-3" onClick={onCreateSlides} disabled={isLoading}>
                    Folien anlegen {isLoading && <i className='fas fa-sync fa-spin ml-1' />}
                </button>
            </div>
            <div className="col-auto">
                <button type="button" className="btn btn-default mt-3" onClick={fetchOutline} disabled={isLoading}>
                    Neu generieren
                </button>
            </div>
        </div>
    </>
}

export default SlideOutlineWizard