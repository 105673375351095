/* global $ */

import React, {useEffect, useMemo, useRef, useState} from 'react'
import {useFloating, useClick, useInteractions, arrow, FloatingArrow} from '@floating-ui/react';
import axios from 'axios';

const ImageZone = (props) => {
    const arrowRef = useRef()
    const fileInputRef = useRef()

    const [source, setSource] = useState(props.source)
    const [contentType, setContentType] = useState(props.contentType);
    const [isLoading, setIsLoading] = useState(false)
    const [showPrompt, setShowPrompt] = useState(false)
    const [prompt, setPrompt] = useState(props.prompt || '')
    const {refs, floatingStyles, context} = useFloating({
        open: showPrompt,
        onOpenChange: setShowPrompt,
        placement: 'top',
        middleware: [
            arrow({
                element: arrowRef,
            }),
        ],
    })

    useEffect(() => {
        $('#image-zone-buttons > [data-toggle="tooltip"]').tooltip()
    })

    const imageSource = useMemo(() => {
        if(source === null) { return source }

        if(/^https?:\/\//.test(source) || source.startsWith('data:image/')) {
            return source
        }

        return `data:${contentType};base64, ${source}`
    }, [source, contentType]);

    const click = useClick(context);

    const {getReferenceProps, getFloatingProps} = useInteractions([
        click,
    ])

    const getImage = async (prompt) => {
        setIsLoading(true)

        try {
            const response = await axios.get(props.path, {
                headers: {'Accept': 'application/json'},
                params: {prompt}
            })

            if(response.status === 200) {
                setSource(response.data.image)
                setContentType(response.data.content_type)
                setPrompt(response.data.prompt)
            }
        } finally {
            setIsLoading(false)
        }
    }

    const regenerate = async () => {
        if(!prompt || prompt === '') { return }

        await getImage(prompt)
    }

    const onSavePrompt = async () => {
        setShowPrompt(false)

        if(prompt !== props.prompt) {
            await regenerate()
        }
    }

    const onAbort = () => {
        setPrompt(props.prompt)
        setShowPrompt(false)
    }

    const uploadFile = async ({target: {result}}) => {
        const response = await axios.post(props.path, {data: result}, {
            headers: {
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
            }
        })

        if(response.status === 200) {
            setSource(result)
        } else {
            alert('Upload fehlgeschlagen')
        }
    }

    const readFile = async ({target: {files}}) => {
        const reader = new FileReader()

        reader.onload = props.onLoadFile || uploadFile

        reader.readAsDataURL(files[0])
    }

    return <div style={{position: "relative", display: 'flex', justifyContent: 'center', width: '100%'}}>
        {isLoading &&
            <div className="d-flex align-items-center justify-content-center bg-gray" style={{minWidth: '100%', minHeight: '250px'}}>
                <i className="fas fa-sync fa-spin fa-2x"/>
            </div>
        }
        {!isLoading && !source &&
            <div className="d-flex align-items-center justify-content-center bg-gray" style={{minWidth: '100%', minHeight: '250px'}}>
                <i className="fas fa-image-slash fa-beat-fade fa-2x"/>
            </div>
        }
        {!isLoading && source &&
            <img className='img-thumbnail' src={imageSource} alt={props.alt} />
        }
        {!isLoading &&
            <div id='image-zone-buttons' style={{
                position: 'absolute',
                bottom: '10px',
                display: 'flex',
                justifyContent: 'center',
                minWidth: '100%'
            }}>
                <div style={{
                    backgroundColor: '#193280',
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '5px',
                    padding: '5px',
                    borderRadius: '5px'
                }}>
                    {!source &&
                            <button type="button" style={{background: 'none', border: 'none', color: '#fff'}}
                                    title="Anweisung bearbeiten" data-toggle="tooltip" data-placement="bottom"
                                    onClick={() => getImage('')}>
                                <i className="fas fa-sparkles"/>
                            </button>
                    }
                    {source &&
                            <button type="button" style={{background: 'none', border: 'none', color: '#fff'}}
                                    ref={refs.setReference}  {...getReferenceProps()} title="Anweisung bearbeiten"
                                    data-toggle="tooltip" data-placement="bottom">
                                <i className="fas fa-sparkles"/>
                            </button>
                    }
                    {prompt !== '' &&
                            <button type="button" style={{background: 'none', border: 'none', color: '#fff'}}
                                    onClick={regenerate} title="Neu generieren" data-toggle="tooltip"
                                    data-placement="top">
                                <i className="fas fa-repeat"/>
                            </button>
                    }
                    <button type='button' style={{background: 'none', border: 'none', color: '#fff'}}
                            title='Bild hochladen' data-toggle='tooltip' data-placement='top'>
                        <input ref={fileInputRef} type="file" accept="image/png, image/jpeg" onChange={readFile}
                               style={{display: 'none'}} multiple={false}/>
                        <i className="fas fa-upload" onClick={() => fileInputRef.current.click()}/>
                    </button>
                </div>
            </div>
        }
        {showPrompt &&
                <div ref={refs.setFloating} style={{
                    ...floatingStyles,
                    backgroundColor: '#fff',
                    border: '1px solid #193280',
                    borderRadius: '5px', padding: '10px', zIndex: '1000'
                }} {...getFloatingProps()} >
                    <FloatingArrow ref={arrowRef} context={context} fill={'#193280'}/>
                    <textarea className='form-control mb-2' value={prompt}
                              onChange={({target: {value}}) => setPrompt(value)}/>
                    <div className="d-flex" style={{gap: "10px"}}>
                        <button className='btn btn-secondary btn-sm btn-block m-0' type='button' onClick={onAbort}>
                            Abbruch
                        </button>
                        <button className='btn btn-primary btn-sm btn-block m-0' type='button' onClick={onSavePrompt}
                                disabled={prompt === ''}>
                            Speichern
                        </button>
                    </div>
                </div>
        }
    </div>
}

export default ImageZone;